import '../../src/stylus/main.styl';
// import * as Sentry from '@sentry/browser';
// import { BrowserTracing } from '@sentry/tracing';

export default (function () {
	// Sentry.init({
	// 	dsn: 'https://fb3be74e22654c1fa1b9a54c25a4c0ce@o199290.ingest.sentry.io/4504028162031616',
	// 	integrations: [new BrowserTracing()],
	// 	environment: GLOBALS.env,
	// 	tracesSampleRate: GLOBALS.env === 'production' ? 0.25 : 1.0,
	// 	debug: false,
	// 	release: GLOBALS.version,
	// });

	const mainHeader = document.getElementById('main-header');
	const hamburgerBtn = document.querySelector('.hamburger');

	if (hamburgerBtn) {
		hamburgerBtn.addEventListener('click', function (e) {
			e.preventDefault();
			mainHeader.classList.toggle('open');
		});
	}

	const signupBtn = document.querySelector('#cta-signup button');

	if (signupBtn) {
		signupBtn.addEventListener('click', function (e) {
			e.preventDefault(e);

			let input = e.target.previousElementSibling;

			if (!input || input.tagName !== 'INPUT') {
				input = e.target.nextElementSibling;
			}

			if (!input || input.tagName !== 'INPUT') {
				return;
			}

			const email = input.value;

			let url = `${GLOBALS.uris.app}/signup`;

			if (email && email.length) {
				url += `?email=${email}`;
			}

			window.location.replace(url);
		});
	}

	// lazy load images
	const lazyImages = document.querySelectorAll('img[data-src]');

	if (lazyImages) {
		lazyImages.forEach(function (img) {
			if (img.hasAttribute('data-src')) {
				img.setAttribute('src', img.dataset.src);
			}
			if (img.hasAttribute('data-srcset')) {
				img.setAttribute('srcset', img.dataset.srcset);
			}
		});
	}
})();
